input[type="text"],
input[type="email"] {
	width: 600px;
	border: none;
	border-bottom: 1px solid grey;
	margin-bottom: 5px;
}

input[type="submit"] {
	width: 200px;
	border-radius: 4px;
	padding: 3px;
	border-width: 1px;
}
textarea {
	height: 10em;
	width: 600px;
}

div[role="alert"] {
	color: red;
	margin-top: 1em;
}

.message-chars-left {
	width: 600px;
	margin: auto;
	text-align: left;
}
